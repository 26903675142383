<template>
    <el-form-item label="摘要" prop="abstractCode">
        <el-select v-model="code" v-bind="$attrs" filterable>
            <el-option label="请选择" value="" />
            <el-option v-for="item in abstracts" :label="item.name" :value="item.code" :key="item.code" />
        </el-select>
    </el-form-item>
</template>

<script>
import Util from 'js/Util';

export default {
    name: 'EfAbstract',
    model: {
        prop: 'selected',
        event: 'change',
    },
    props: {
        selected: {
            type: String,
            default: '',
        },
        type: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            code: '',
            abstracts: [],
        };
    },
    watch: {
        selected: {
            handler(n, o) {
                //假设：null与''等价
                if (Util.isEmpty(n)) {
                    n = '';
                }
                this.code = n;
            },
            immediate: true,
        },
        code: {
            handler(n, o) {
                this.$emit('change', n);
            },
            immediate: true,
        },
    },
    mounted() {
        (async () => {
            if (Util.isEmpty(this.type)) {
                return;
            }
            const rst = (await this.$efApi.adjustAbstractApi.queryAbstractList(this.type)) || [];
            rst.forEach((e) => {
                const item = new Item();
                Util.copyProperties(e, item);
                this.abstracts.push(item);
            });
        })();
    },
};

class Item {
    constructor(name, code) {
        this.name = name;
        this.code = code;
    }
}
</script>
