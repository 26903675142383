<template>
    <div class="Surplus">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="80px"
            >
                <el-row>
                    <el-col :span="8">
                        <ef-biz-code v-model="form.code" />
                    </el-col>
                    <ef-dept-type-and-dept-select @selectDeptType="setDeptType" @selectDept="setDept" />
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <ef-abstract :type="adjustAbstractType" v-model="form.abstractCode" />
                    </el-col>
                    <el-col :span="8">
                        <ef-review-status v-model="form.reviewStatus" />
                    </el-col>
                    <el-col :span="8">
                        <ef-stock-biz-status v-model="form.stockStatus" />
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <ef-start-date v-model="form.startTime" />
                    </el-col>
                    <el-col :span="8">
                        <ef-end-date v-model="form.endTime" />
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="盘点编码">
                            <el-input placeholder="请填写" v-model="form.stockCheckCode" style="width: 215px" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="handleQuery" size="small" v-if="hasPrivilege('menu.stock.surplus.open')">
                查询
            </el-button>
            <el-button
                type="primary"
                @click="handleCreate"
                size="small"
                v-if="hasPrivilege('menu.stock.surplus.create')"
                >新建
            </el-button>
            <el-button
                type="primary"
                @click="showReviewDialog"
                :disabled="disableReviewBtn"
                size="small"
                v-if="hasPrivilege('menu.stock.surplus.review')"
                >审核
            </el-button>
            <el-button
                type="primary"
                @click="changeMultipleBizStock"
                :disabled="disableChangeStockBtn"
                size="small"
                v-if="hasPrivilege('menu.stock.surplus.stock')"
                >入库
            </el-button>
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.stock.surplus.export')"
                >导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                border
                stripe
                style="width: 100%"
                id="printMe"
                :data="tableData"
                ref="table"
                size="mini"
                :highlight-current-row="true"
                max-height="430"
                @selection-change="handleSelectionChange"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="编码" width="240" prop="code" v-if="showColumn('code')" />
                <el-table-column label="机构名称" width="160" prop="deptName" v-if="showColumn('deptName')" />
                <el-table-column label="摘要" width="160" prop="abstractName" v-if="showColumn('abstractName')" />
                <el-table-column
                    label="批发价总金额"
                    width="100"
                    prop="wholeSaleMoneySum"
                    key="wholeSaleMoneySum"
                    v-if="showColumn('wholeSaleMoneySum') && showInPrice()"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.wholeSaleMoneySum | moneyToYuan }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="审核状态"
                    width="140"
                    prop="reviewStatus"
                    key="reviewStatus"
                    v-if="showColumn('reviewStatus')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.reviewStatus | reviewStatus }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="单据状态"
                    width="160"
                    prop="stockStatus"
                    key="stockStatus"
                    v-if="showColumn('stockStatus')"
                    sortable
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.stockStatus | stockInStatus }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="入库时间"
                    width="160"
                    prop="stockChangeTime"
                    v-if="showColumn('stockChangeTime')"
                />
                <el-table-column label="创建人" width="120" prop="creator" v-if="showColumn('creator')" />
                <el-table-column label="创建时间" width="160" prop="createTime" v-if="showColumn('createTime')" />
                <el-table-column
                    label="操作"
                    min-width="275"
                    header-align="center"
                    prop="operate"
                    key="operate"
                    v-if="showColumn('operate')"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="rowDetail(scope.row)"
                            v-if="hasPrivilege('menu.stock.surplus.open')"
                            >查看
                        </el-button>
                        <el-button
                            size="mini"
                            type="warning"
                            @click="rowEdit(scope.row)"
                            :disabled="scope.row.disableEditBtn"
                            v-if="hasPrivilege('menu.stock.surplus.edit')"
                        >
                            编辑
                        </el-button>
                        <el-button
                            size="mini"
                            type="success"
                            @click="changeSingleBizStock(scope.row)"
                            :disabled="scope.row.disableChangeStockBtn"
                            v-if="hasPrivilege('menu.stock.surplus.stock')"
                            >入库
                        </el-button>
                        <el-button
                            size="mini"
                            type="danger"
                            @click="handleDelete(scope.row)"
                            :disabled="scope.row.disableDeleteBtn"
                            v-if="hasPrivilege('menu.stock.surplus.delete')"
                            >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <ef-pagination
                @pageChange="pageChange"
                :total="total"
                :default-limit="form.limit"
                :current-page="form.page"
            />
        </el-card>
        <ef-review-biz ref="review" :baseUrl="baseUrl" @completed="handleQuery" />
    </div>
</template>

<script>
import Util from 'js/Util';
import EfBizCode from 'components/EfBizCode';
import EfDeptTypeAndDeptSelect from 'components/EfDeptTypeAndDeptSelect';
import StockBizCommon from 'js/StockBizCommon';
import EfAbstract from 'components/EfAbstract';
import EfStockBizStatus from 'components/EfStockBizStatusEnter';
import EfReviewStatus from 'components/EfReviewStatus';
import EfStartDate from 'components/EfStartDate';
import EfEndDate from 'components/EfEndDate';
import EfPagination from 'components/EfPagination';
import EfReviewBiz from 'components/EfReviewBiz';
import UrlUtils from 'js/UrlUtils';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    name: 'Surplus',
    components: {
        EfReviewBiz,
        EfPagination,
        EfEndDate,
        EfStartDate,
        EfStockBizStatus,
        EfReviewStatus,
        EfBizCode,
        EfDeptTypeAndDeptSelect,
        EfAbstract,
        CheckTableShowColumnDialog,
    },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            selectedBizArr: [],
            baseUrl: this.$efApi.stockSurplusApi.baseUrl,
            form: {
                deptCode: null,
                deptType: null,
                startTime: Util.formatDateOfLastMonth(),
                endTime: Util.nowFormatDate(),
                abstractCode: '',
                reviewStatus: '',
                code: '',
                stockStatus: '',
                page: 1,
                limit: 100,
                stockCheckCode: null,
            },
            total: 0,
            adjustAbstractType: StockBizCommon.AdjustAbstractTypeEnum.OVERFLOW,
            tableData: [],
        };
    },
    computed: {
        //禁用/启用按钮
        disableReviewBtn() {
            if (this.selectedBizArr.length === 0) {
                return true;
            }
            const disableReviewBtnFlag = this.selectedBizArr.findIndex((e) => {
                return e.disableReviewBtn;
            });
            return disableReviewBtnFlag >= 0;
        },
        disableChangeStockBtn() {
            if (this.selectedBizArr.length === 0) {
                return true;
            }
            const disableChangeStockBtnFlag = this.selectedBizArr.findIndex((e) => {
                return e.disableChangeStockBtn;
            });
            return disableChangeStockBtnFlag >= 0;
        },
    },
    mounted() {},
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    methods: {
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        changeMultipleBizStock() {
            const codes = this.selectedBizArr.map((e) => {
                return e.code;
            });
            this.changeStock(codes);
        },
        changeSingleBizStock(row) {
            this.changeStock([row.code]);
        },
        changeStock(codes) {
            const _this = this;
            UrlUtils.PostRemote(
                _this,
                '/stock/overflow/in',
                {
                    codes: codes,
                    message: '确认入库该单据么?入库后该单据商品库存将立即变更,该操作不可撤销,请小心操作!!!',
                },
                null,
                () => {
                    this.$message.success('入库成功');
                    this.handleQuery();
                }
            );
        },
        handleSelectionChange(selections) {
            this.selectedBizArr = selections;
        },
        showReviewDialog() {
            this.$refs.review.open(this.selectedBizArr);
        },
        pageChange(page, limit) {
            this.form.page = page;
            this.form.limit = limit;
            this.queryList();
        },
        handleQuery() {
            this.form.page = 1;
            this.queryList();
        },
        setDept(deptCode, deptName) {
            this.form.deptCode = deptCode;
            this.form.deptName = deptName;
        },
        setDeptType(deptType) {
            this.form.deptType = deptType;
            if (deptType) {
                this.handleQuery();
            }
        },

        handleCreate() {
            Util.nameJump(this, 'menu.stock.surplus.create', ['库存管理', '报溢调整', '新建报溢']);
        },
        rowDetail(row) {
            Util.nameJump(this, 'menu.stock.surplus.detail', ['库存管理', '报溢调整', '报溢详情'], {
                form: row,
                code: row.code,
            });
        },
        rowEdit(row) {
            Util.nameJump(this, 'menu.stock.surplus.edit', ['库存管理', '报溢调整', '编辑报溢'], {
                form: row,
                code: row.code,
            });
        },

        handleExport() {
            const codes = (this.$refs.table.selection || []).map((e) => e.code);

            UrlUtils.Export(this, '报溢调整', '/stock/overflow/export', this.form, codes);
        },

        async queryList() {
            const rst = await this.$efApi.stockSurplusApi.page(this.form);
            this.tableData = rst.data.map((e) => {
                return {
                    ...e,
                    wholeSaleMoneySum: 0,
                    //根据状态设置操作按钮禁用或启用
                    disableEditBtn: !StockBizCommon.validateCanEdit(e.reviewStatus),
                    disableDeleteBtn: !StockBizCommon.validateCanDelete(e.reviewStatus),
                    disableReviewBtn: !StockBizCommon.validateCanReview(e.reviewStatus),
                    disableChangeStockBtn: !StockBizCommon.validateCanChangeStock(e.reviewStatus, e.stockStatus),
                };
            });
            this.total = rst.count;
            for (let i = 0; i < this.tableData.length; i++) {
                const bizDetail = this.tableData[i];
                //根据code查询单据商品的批发价和数量并进行汇总显示
                this.$efApi.stockSurplusApi.detail(bizDetail.code).then((e = {}) => {
                    let wholeSaleMoneySum = 0;
                    for (let j = 0; j < e.wholeSalePrices.length; j++) {
                        //批发单价*数量并累加
                        wholeSaleMoneySum += (e.wholeSalePrices[j] || 0) * (e.counts[j] || 0);
                    }
                    bizDetail.wholeSaleMoneySum = wholeSaleMoneySum;
                });
            }
        },
        async handleDelete(row) {
            await this.$efApi.stockSurplusApi.deleteBiz(row.code);
            this.handleQuery();
        },
    },
};
</script>

<style scoped>
.Surplus .el-form-item {
    margin-bottom: 0;
}
</style>
